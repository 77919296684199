<template>
  <div class="carrier-card">
    <div class="image-container">
      <img :src="carrierImage.url" :alt="carrier.carrier" />
      <span class="divider"></span>
      <div class="icons-container">
        <div class="icons-container__icon icons-container__icon--favorite" title="Favorite">
          <b-icon
            v-if="carrier.fav"
            @click="favCarrier(carrier.name, index, false)"
            class="star-fill"
            icon="star-fill"
            aria-hidden="true"
          ></b-icon>
        </div>
        <div class="icons-container__icon icons-container__icon--guaranteed" title="Guaranteed">
          <span v-if="checkIfCarrierIsGuaranteed"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              class="bi bi-lightning-fill"
              viewBox="0 0 16 16"
              fill="#FF9900"
            >
              <path
                d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"
              /></svg
          ></span>
        </div>
        <div
          class="icons-container__icon icons-container__icon--tsa"
          title="TSA"
          v-if="carrier.isTsa"
        >
          <img src="@/assets/images/TSA-logo.png" alt="Insurance" />
        </div>
      </div>
    </div>
    <div class="section-container">
      <div class="section-container__text-container">
        <span class="section-container__title">Service class</span>
        <p class="section-container__text">
          {{ carrier.serviceClass + addCarrierModeToName(carrier.carrier) }}
        </p>
      </div>
      <div class="section-container__text-container">
        <span class="section-container__title">{{ $t("carriers.transitTime") }}</span>
        <p class="section-container__text">
          {{ setTransitTime }}
        </p>
      </div>
      <span class="divider"></span>
    </div>
    <div class="section-container">
      <div class="section-container__text-container">
        <span class="section-container__title">{{ $t("carriers.liability") }}</span>
        <p class="section-container__text">
          ${{ carrier.newLiability }} {{ $t("carriers.new") }} / ${{ carrier.usedLiability }}
          {{ $t("carriers.used") }}
        </p>
      </div>
      <div class="section-container__text-container section-container__text-container--performance">
        <span class="section-container__title">{{ $t("carriers.performance") }}</span>
        <span>
          <span class="section-container__title">{{ $t("carriers.pickup") }}</span>
          <span class="section-container__text">
            {{
              carrier.pickupPerformance ? Math.ceil(carrier.pickupPerformance) + "%" : "NA"
            }} </span
          >/
          <span class="section-container__title">{{ $t("carriers.delivery") }}</span>
          <span class="section-container__text">
            {{
              carrier.deliveryPerformance ? Math.ceil(carrier.deliveryPerformance) + "%" : "NA"
            }}
          </span>
        </span>
      </div>
      <span class="divider"></span>
    </div>
    <div class="section-container">
      <div class="section-container__text-container">
        <span class="section-container__title">{{ $t("carriers.quoteExpiration") }}</span>
        <p class="section-container__text">{{ carrier.expiration | dateFormat }}</p>
      </div>
      <span class="section-container__title section-container__title--alert">
        <span
          @click="selectedCarrier(false, carrier)"
          class="section-container__rate-message section-container__title--alert"
          v-if="showCarrierMessage(carrier)"
        >
          <i v-b-popover.hover.top="ratePickupMessage">
            <img
              class="icons-tools"
              src="@/assets/scss/icons/info-circle-svgrepo-com.svg"
              alt="ratePickupMessage"
          /></i>
          {{ $t("settings.truckManagement.rateMessage") }}
          <span v-if="showCarrierMessage(carrier)" class="section-container__pickup-price">
            {{ formatEasyPostPriceWithPickup(carrier) | priceToUsd }}
          </span>
        </span>
        <br />
        <div v-if="carrier.alert">
          <i v-b-popover.hover="carrier.description" class="ion ion-warning-outline"></i>
          {{ $t("settings.truckManagement.serviceThreshold") }}
        </div>
      </span>
      <span class="divider" v-if="canDispatch === false"></span>
    </div>
    <div class="section-container" v-if="canDispatch || canDispatch === null">
      <button
        class="quote-button quote-button--blue"
        :class="checkIfCarrierHasExpired(carrier) || loadingInsurance ? 'disabled-button' : ''"
        @click="selectedCarrier(false, carrier)"
        :disabled="checkIfCarrierHasExpired(carrier) || loadingInsurance"
      >
        <div
          class="container-info"
          v-b-popover.hover.top="
            checkIfCarrierHasExpired(carrier) ? $t('carriers.priceExpired') : ''
          "
          v-if="!loadingInsurance"
        >
          <div class="container-info__label">
            <p class="container-info__label--drop-off" v-if="showCarrierMessage(carrier)">
              BOOK <br />
              DROP OFF
            </p>
            <p v-else>{{ $t("carriers.book") }}</p>
          </div>
          <div class="container-info__prices">
            <span>USD{{ formatEasyPostPriceWithoutPickup(carrier).toFixed(2) | priceToUsd }}</span>
          </div>
        </div>
        <b-spinner small v-if="loadingInsurance" variant="light" label="Spinning"></b-spinner>
      </button>
      <button
        class="quote-button quote-button--green"
        :class="checkIfCarrierHasExpired(carrier) || loadingInsurance ? 'disabled-button' : ''"
        @click="selectedCarrier(true, carrier)"
        :disabled="checkIfCarrierHasExpired(carrier) || loadingInsurance"
      >
        <div
          class="container-info"
          v-b-popover.hover.top="
            checkIfCarrierHasExpired(carrier) ? $t('carriers.priceExpired') : ''
          "
          v-if="!loadingInsurance"
        >
          <div class="container-info__label">
            <p>
              {{ $t("carriers.bookWith") }}
              {{ $t("carriers.insurance") }}
            </p>
          </div>
          <div class="container-info__prices">
            <span>
              USD{{
                (formatEasyPostPriceWithoutPickup(carrier) + aditionalInsurance).toFixed(2)
                  | priceToUsd
              }}
            </span>
          </div>
        </div>
        <b-spinner small v-if="loadingInsurance" variant="light" label="Spinning"></b-spinner>
      </button>
    </div>
    <div class="section-container-text" v-if="canDispatch === false">
      <div class="carrier-price">
        <span> <b>PRICE</b> {{ carrier.price.toFixed(2) | priceToUsd }}</span>
      </div>
      <div class="carrier-insurance-price">
        <span>
          <b>PRICE WITH INSURANCE</b>
          {{ (carrier.price + aditionalInsurance).toFixed(2) | priceToUsd }}</span
        >
      </div>
    </div>
    <b-modal v-model="showModal" hide-header hide-footer size="lg" no-close-on-backdrop centered>
      <QuoteAcceptanceModal
        :insurance="insurance"
        :aditionalInsurance="aditionalInsurance"
        :showPickupPrice="showPickupPrice"
        :carrier="carrier"
        :lineItems="lineItems"
        @closeModal="closeModal"
        @optionSelected="optionSelected"
      />
    </b-modal>
  </div>
</template>

<script>
import optionsCarriers from "@/Extend/OptionsCarriers";

export default {
  name: "OptionsCarriers",
  mixins: [optionsCarriers],
  methods: {
    showCarrierMessage(carrier) {
      return carrier.source === "easy-post" && (carrier.scac === "UPSN" || carrier.scac === "FDEG" || carrier.scac === "USPS");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../../assets/scss/components/Modal.scss";
.carrier-card {
  @include font-small-standart-text;
  display: grid;
  grid-template-columns: 15% 19% 19% 19% 28%;
  width: 100%;
  border: 1px solid $color-grey-border;
  border-radius: 20px;
  background: $color-white;
  box-shadow: 0px 0px 6px #00000029;
  color: $color-primary-company;
  @media (min-width: 1230px) {
    grid-template-columns: 13% 17% 22% 27% 19%;
  }
  @media (min-width: 1300px) {
    grid-template-columns: 13% 17% 22% 26% 19%;
  }
  @media (min-width: 1350px) {
    grid-template-columns: 16% 17% 26% 25% 16%;
  }
  @media (min-width: 1390px) {
    grid-template-columns: 16% 17% 26% 23% 18%;
  }
  @media (min-width: 1550px) {
    grid-template-columns: 16% 17% 26% 17% 19%;
  }
}
.section-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
  &__text-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    &--performance {
      margin-top: 5px;
    }
  }
  &__title {
    padding-right: 5px;
    font-weight: bold;
    font-size: 13px;
    i {
      color: #ff9900;
      font-style: italic;
      font-size: 23px;
    }
  }
  &__rate-message {
    font-size: 13px;
    display: -webkit-inline-box;
    cursor: pointer;
  }
  &__pickup-price {
    color: red;
  }
  &__text {
    margin: 0px;
    overflow-wrap: break-word;
    inline-size: 100%;
  }
}

.section-container-text {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img {
    width: 100%;
    max-width: 100px;
    object-fit: contain;
  }
}

.divider {
  position: absolute;
  right: 0;
  height: 90%;
  border-left: 1px solid #dedede;
}
.quote-button {
  width: 100%;
  max-width: 200px;
  margin: 4px auto;
  padding: 5px;
  height: 43px;
  border-radius: 15px;
  border: none;
  @include font-small-button-text;
  font-weight: bold;
  &--green {
    background: $color-carrier-quote-insurance-button;
    color: $color-carrier-quote-insurance-button-text;
  }
  &--blue {
    background: $color-carrier-quote-button;
    color: $color-white;
  }
}
.disabled-button {
  background-color: $color-disabled;
}
.container-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: clamp(0.6rem, 2vw, 0.7rem);
  &__label {
    margin: auto;
    width: 50%;
    max-width: 95px;
    font-size: 0.68rem;
    word-break: break-word;
    p {
      margin: 0px;
    }
    &--drop-off {
      text-align: start;
      padding-left: 10px;
    }
  }
  &__prices {
    width: 50%;
    display: flex;
    flex-direction: column;
    max-width: 95px;
    text-align: center;
    word-break: break-all;
    letter-spacing: 0.5px;
    span {
      margin: auto 0;
      font-size: 11px;
    }
    p {
      font-weight: bold;
    }
    label {
      font-size: 10px;
    }
  }
}
.icons-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 5px;
  bottom: 10px;
  gap: 2px;
  &__icon {
    color: #ff9900;
    &--favorite {
      display: flex;
      align-items: center;
      font-size: 13px;
    }
    &--guaranteed svg {
      height: 13px;
    }
  }
}
.icons-tools {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
::v-deep .modal-content {
  @media (max-width: 991px) {
    width: 100%;
  }
}
</style>
