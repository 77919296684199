<template>
  <div class="option-container accordion" role="tablist">
    <div
      class="option-container__info"
      @click="collapse()"
      v-b-toggle="'collapse' + optionLTL"
      role="tab"
    >
      <!-- <div class="image-container__fav-star"></div> -->
      <div class="image-container-icons">
        <div v-if="carrier.fav" title="Favorite">
          <b-icon
            @click="favCarrier(carrier.name, index, false)"
            class="star-fill"
            icon="star-fill"
            aria-hidden="true"
          ></b-icon>
        </div>
        <div title="Guaranteed" v-if="checkIfCarrierIsGuaranteed" class="guaranteed-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="bi bi-lightning-fill"
            viewBox="0 0 16 16"
            fill="#ff9900"
          >
            <path
              d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"
            />
          </svg>
        </div>
        <div class="tsa-icon" title="TSA" v-if="carrier.isTsa">
          <img src="@/assets/images/TSA-logo.png" alt="Insurance" />
        </div>
      </div>
      <div class="image-container">
        <img
          class="image-container__image"
          :src="carrierImage.url"
          :alt="carrier.carrier"
        />
      </div>
      <div class="option-container__info__description">
        <div class="option-container__item option-container__item--restrictions">
          <span>
            <!-- Standard LTL <br /> -->
            {{ $t("carriers.transitTime") }}: {{ setTransitTime }}
          </span>
          <p class="option-container__performance">{{ $t("carriers.performance") }}:</p>
          <p>
            Pickup:&nbsp;
            <span class="option-container__item--percentage">
              {{
                carrier.pickupPerformance
                  ? Math.ceil(carrier.pickupPerformance) + "%"
                  : "NA"
              }}
            </span>
            &nbsp;/&nbsp;Delivery:&nbsp;
            <span class="option-container__item--percentage">
              {{
                carrier.deliveryPerformance
                  ? Math.ceil(carrier.deliveryPerformance) + "%"
                  : "NA"
              }}
            </span>
          </p>
        </div>
      </div>
      <div class="collapse-button">
        <button class="collapse-button__button" :class="isCollapsed">
          <i
            class="ion ion-chevron-forward-outline collapse-button__icon collapse-icon"
          ></i>
        </button>
      </div>
    </div>
    <b-collapse
      :id="'collapse' + optionLTL"
      class="option-container__collapse"
      accordion="my-accordion"
      role="tabpanel"
    >
      <div>
        <div class="option-container__collapse__title-liability">
          <span class="item-text">
            <b>Service class:</b>
            {{ carrier.serviceClass + addCarrierModeToName(carrier.carrier) }}
            <p>
              <b>{{ $t("carriers.liability") }}: &nbsp;</b>
              ${{ carrier.newLiability }} {{ $t("carriers.new") }} / ${{
                carrier.usedLiability
              }}
              {{ $t("carriers.used") }}
            </p>
          </span>
        </div>
        <p class="option-container__collapse__warning">
          <b>{{ $t("carriers.quoteExpiration") }}:</b>
          {{ carrier.expiration | dateFormat }}
          <br />
          <br />
          <span
            @click="selectedCarrier(false, carrier)"
            class="option-container__rate-message"
            v-if="showCarrierMessage(carrier)"
          >
            <i v-b-popover.hover.top="ratePickupMessage">
              <img
                class="icons-tools"
                src="@/assets/scss/icons/info-circle-svgrepo-com.svg"
                alt="ratePickupMessage"
            /></i>
            {{ $t("settings.truckManagement.rateMessage") }}
            <span
              v-if="showCarrierMessage(carrier)"
              class="option-container__pickup-price"
            >
              {{ formatEasyPostPriceWithPickup(carrier) | priceToUsd }}
            </span>
          </span>
          <br />
          <span
            v-if="carrier.alert"
            v-b-popover.hover="carrier.description"
            class="warning-alert-icon"
          >
            <i class="ion ion-warning-outline"></i>
            Tap to see service threshold
          </span>
        </p>
      </div>
    </b-collapse>
    <div
      class="option-container__item option-container__book-buttons"
      v-if="!$options.filters.checkPermission('post:tender')"
    >
      <div class="carrier-price">
        <span>
          <b>PRICE</b
          >{{ formatEasyPostPriceWithoutPickup(carrier).toFixed(2) | priceToUsd }}</span
        >
      </div>
      <div class="carrier-insurance-price">
        <span>
          <b>PRICE WITH INSURANCE</b>
          {{
            (formatEasyPostPriceWithoutPickup(carrier) + aditionalInsurance).toFixed(2)
              | priceToUsd
          }}</span
        >
      </div>
    </div>
    <div
      class="option-container__item option-container__book-buttons"
      v-if="$options.filters.checkPermission('post:tender')"
    >
      <button
        class="quote-button quote-button--blue"
        :class="
          checkIfCarrierHasExpired(carrier) || loadingInsurance ? 'disabled-button' : ''
        "
        @click="selectedCarrier(false, carrier)"
        :disabled="checkIfCarrierHasExpired(carrier)"
      >
        <div
          class="quote-button__text"
          v-b-popover.hover.top="
            checkIfCarrierHasExpired(carrier) ? $t('carriers.priceExpired') : ''
          "
          v-if="!loadingInsurance"
        >
          <span>{{
            showCarrierMessage(carrier) ? $t("carriers.bookDropOff") : $t("carriers.book")
          }}</span>
          <span>USD{{ formatEasyPostPriceWithoutPickup(carrier) | priceToUsd }}</span>
        </div>
        <b-spinner
          small
          v-if="loadingInsurance"
          variant="light"
          label="Spinning"
        ></b-spinner>
      </button>
      <button
        class="quote-button quote-button--green"
        :class="
          checkIfCarrierHasExpired(carrier) || loadingInsurance ? 'disabled-button' : ''
        "
        @click="selectedCarrier(true, carrier)"
        :disabled="checkIfCarrierHasExpired(carrier) || loadingInsurance"
      >
        <div
          class="quote-button__text"
          v-b-popover.hover.top="
            checkIfCarrierHasExpired(carrier) ? $t('carriers.priceExpired') : ''
          "
          v-if="!loadingInsurance"
        >
          <span class="ellipsis-text">{{ $t("carriers.bookWithInsurance") }}</span>
          <span>
            USD{{
              (formatEasyPostPriceWithoutPickup(carrier) + aditionalInsurance).toFixed(2)
                | priceToUsd
            }}
          </span>
        </div>
        <b-spinner
          small
          v-if="loadingInsurance"
          variant="light"
          label="Spinning"
        ></b-spinner>
      </button>
      <b-modal
        v-model="showModal"
        hide-header
        hide-footer
        size="lg"
        no-close-on-backdrop
        centered
      >
        <QuoteAcceptanceModal
          :insurance="insurance"
          :aditionalInsurance="aditionalInsurance"
          :showPickupPrice="showPickupPrice"
          :carrier="carrier"
          :lineItems="lineItems"
          @closeModal="closeModal"
          @optionSelected="optionSelected"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import optionsCarriers from "@/Extend/OptionsCarriers";

export default {
  /* eslint-disable operator-linebreak */
  name: "OptionsCarriersResponsive",
  props: {
    // Change to object when info retrieved from endpoint
    optionLTL: Number,
  },
  mixins: [optionsCarriers],
  data() {
    return {
      selectedAccount: null,
      isCollapsed: "is-collapsed",
      ratePickupMessage:
        "You can proceed  in the next step with the rate including pickup, please click on book.",
    };
  },
  methods: {
    collapse() {
      if (this.isCollapsed === "is-collapsed") {
        this.isCollapsed = "not-collapsed";
      } else {
        this.isCollapsed = "is-collapsed";
      }
    },
    showCarrierMessage(carrier) {
      return (
        carrier.source === "easy-post" &&
        (carrier.scac === "UPSN" || carrier.scac === "FDEG")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../../assets/scss/components/Modal.scss";

.option-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border: 1px solid rgb(163, 163, 163);
  border-radius: 15px;
  background: $color-white;
  padding: 5%;
  position: relative;
  &__item {
    display: flex;
    text-align: start;
    color: $color-primary-company;
    font-weight: bold;
    &--restrictions {
      flex-direction: column;
    }
    p {
      font-weight: bold;
    }
    span {
      font-weight: bold;
    }
    &--percentage {
      font-weight: normal !important;
    }
  }
  &__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 3%;
    &__description {
      p {
        margin: 0 !important;
      }
    }
  }
  &__book-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__collapse {
    width: 100%;
    border-top: 2px solid $color-gray-dark;
    margin-top: 1%;
    margin-bottom: 3%;
    padding: 5% 2% 0 2%;
    text-align: left;
    &__title-liability {
      display: flex;
      flex-direction: row;
      p {
        font-weight: 400;
        margin: 0px;
      }
    }
    &__warning {
      font-weight: normal;
    }
    &__restrictions {
      color: orange;
      text-decoration: underline;
      font-style: italic;
    }
    &__pickup-price {
      color: red;
    }
    &__rate-message {
      cursor: pointer;
    }
  }
}
.option-container__item-text {
  display: flex;
  text-align: start;
  color: $color-primary-company;
}
.disabled-button {
  @include font-small-button-text;
  background-color: $color-gray;
}

.image-container {
  position: relative;
  padding: 10px;
  &__image {
    max-width: 200px;
    width: 100%;
  }
}

.image-container-icons {
  position: absolute;
  display: flex;
  width: 100%;
  top: 7px;
  left: 5px;
  gap: 3px;
  & .star-fill {
    color: #ff9900;
    font-size: 18px;
  }
  & .guaranteed-icon svg {
    height: 18px;
  }
  & div {
    width: 24px;
  }
}

.collapse-button {
  &__button {
    border: none;
    background: transparent;
  }
  &__icon {
    color: $color-primary-company;
    font-size: 2rem;
  }
}
.quote-button {
  width: 100%;
  min-width: 140px;
  margin: 5px auto;
  padding: 2% 3%;
  height: 40px;
  border-radius: 15px;
  border: none;
  @include font-small-button-text;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  &--green {
    background: $color-carrier-quote-insurance-button;
    color: $color-carrier-quote-insurance-button-text;
  }
  &--blue {
    background: $color-carrier-quote-button;
    color: $color-white;
  }
  &__text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 500px) {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 62%;
    }
  }
}
.disabled-button {
  background-color: $color-disabled;
}
.collapse-button {
  transform: rotate(0deg);
  transition: 0.3s;
}
.collapse-button .not-collapsed {
  transform: rotate(90deg);
  transition: 0.3s;
}
.warning-alert-icon i {
  color: #e6bb6f;
  font-size: 23px;
}
.icons-tools {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
// modal styles
::v-deep .modal-content {
  border: 1px solid #00b9cc;
  border-radius: 10px;
  background-color: $color-gray-light;
}
</style>
