<template>
  <div class="info-about-selected-carrier">
    <span> {{ $t("carriers.modalInfo.selectedCarrier") }} </span>
    <p v-if="!insurance">
      {{ transportSelected.name }}:
      {{ formatEasyPostPriceWithoutPickup(carrier).toFixed(2) | priceToUsd }}
    </p>
    <p v-else>
      {{ transportSelected.name }}:
      {{
        (formatEasyPostPriceWithoutPickup(carrier) + aditionalInsurance).toFixed(2)
          | priceToUsd
      }}
    </p>
  </div>
</template>

<script>
import FormatEasyPostPrices from "../../../../../../Extend/FormatEasyPostPrices";

export default {
  name: "InfoAboutSelectedCarrier",
  mixins: [FormatEasyPostPrices],
  props: {
    insurance: Boolean,
    transportSelected: Object,
    carrier: Object,
    aditionalInsurance: Number,
  },
};
</script>

<style lang="scss" scoped>
.info-about-selected-carrier {
  span {
    font-weight: bold;
  }
  p {
    margin: 0px;
  }
}
</style>
